
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonPopover,
  IonDatetime,
  IonText,
  IonLabel,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { format, parseISO } from "date-fns";
import settingsApp from "@/libs/settings";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import httpApp from "@/libs/http";
import VendorNavComponent from "./VendorNavComponent.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
export default defineComponent({
  name: "Commissions",
  setup() {
    const { t, tm, te } = useI18n();
    const router = useRouter();
    const storeX = useStore();
    const { openToast, createLoading } = useGlobalFunctions();
    const commissionsData = ref(null);
    const totalCommissions = ref(null);

    const customDatetime = ref();
    const dateFrom = ref();
    const dateTo = ref();
    const fromDateCalendar = ref();
    const toDateCalendar = ref();

    const formatDate = (value: string) => {
      return format(parseISO(value), "MMM dd yyyy");
    };

    const getSellerCommissions = async (event) => {
      event.preventDefault();
      const loading = await createLoading(t("pleaseWait"));
      loading.present();
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getSellerCommissions"), {
          store_view: storeX.state.storeCode,
          from_date: dateFrom.value,
          to_date: dateTo.value,
        })
        .then(async (response: any) => {
          const resp = response;
          if (resp.success) {
            commissionsData.value = resp.items;
            totalCommissions.value = resp.total_commission;
            loading.dismiss();
          } else {
            commissionsData.value = null;
            totalCommissions.value = null;
            loading.dismiss();
            if (te("apiMessages.codes." + resp.error.code)) {
              openToast(t("apiMessages.codes." + resp.error.code), "danger");
              loading.dismiss();
            } else {
              openToast(resp.error.message, "danger");
              loading.dismiss();
            }
          }
        })
        .catch((error) => {
          console.log(error, "error");
          loading.dismiss();
        });
    };

    const dateChanged = (ev, type) => {
      if (type == "from_date") {
        dateFrom.value = formatDate(ev.detail.value);
        fromDateCalendar.value.$el.confirm(true);
      } else {
        dateTo.value = formatDate(ev.detail.value);
        toDateCalendar.value.$el.confirm(true);
      }
    };

    return {
      storeX,
      t,
      tm,
      te,
      router,
      dateChanged,
      commissionsData,
      customDatetime,
      fromDateCalendar,
      toDateCalendar,
      dateFrom,
      dateTo,
      formatDate,
      getSellerCommissions,
      totalCommissions,
    };
  },

  components: {
    IonPage,
    IonContent,
    IonList,
    IonItem,
    IonPopover,
    IonDatetime,
    IonText,
    IonLabel,
    MainHeader,
    VendorNavComponent,
    MainFooter,
  },
});
